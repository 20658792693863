import React, { Suspense } from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import NotFound from "../components/NotFound"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" image={socialImage} />
      <NotFound />
    </Layout>
  )
}

export default NotFoundPage
